$turquoise: hsl(185, 80%, 43%);
$darkTurquoise: hsl(192, 29%, 30%);
$paleGrey: hsl(192, 10%, 50%);
// $orange: hsl(8, 100%, 68%);
$orange: #ddb228;
$blue: hsl(199, 18%, 33%);
$navy: hsl(225, 38%, 23%);
$lightBlue: hsl(178, 63%, 44%);
$yellow: #ddb228;

$sm: "600px";
$md: "900px";
$lg: "1200px";
$xl: "1536px";

$maxWidth: 1310px;
