.imgWrapper {
  padding: 50px 0px;
  width: 100%;
}

.imgContainer {
  max-inline-size: 100%;
  block-size: auto;
  margin: auto;

  @media only screen and (max-width: 4000px) {
    width: 500px;
    height: 500px;
  }
  @media only screen and (max-width: 1200px) {
    width: 500px;
    height: 500px;
  }
  @media only screen and (max-width: 1100px) {
    width: 500px;
    height: 500px;
  }
  @media only screen and (max-width: 1000px) {
    width: 400px;
    height: 400px;
  }
  @media only screen and (max-width: 800px) {
    width: 500px;
    height: 500px;
  }
  @media only screen and (max-width: 600px) {
    width: 400px;
    height: 400px;
  }
  @media only screen and (max-width: 425px) {
    width: 300px;
    height: 300px;
  }
  @media only screen and (max-width: 320px) {
    width: 250px;
    height: 250px;
  }
}

.titleWrapper {
  h1 {
    font-size: 38px;
    line-height: 48px;
    font-weight: 600;
    @media only screen and (max-width: 800px) {
      text-align: center;
    }
  }
}
.textContainer {
  width: 100%;
  padding-top: 100px;

  &:first-of-type {
    padding-top: 30px;
    @media only screen and (max-width: 600px) {
      padding-top: 0px;
    }
  }

  @media only screen and (max-width: 1000px) {
    padding-top: 50px;
  }
  @media only screen and (max-width: 500px) {
    padding-top: 0px;
  }
  @media only screen and (max-width: 800px) {
    text-align: center;
  }
}

.aboutContainer {
  margin: auto;
  max-width: 1310px;
  @media only screen and (max-width: 1309px) {
    padding: 0px 24px;
  }
  @media only screen and (max-width: 600px) {
    padding: 0px 16px;
  }
}

.textImgSection {
  display: flex;
  gap: 30px;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    gap: 0px;
  }
}

.center {
  margin: auto;
  text-align: center;
}

.textWrapper {
  padding-right: 100px;

  @media only screen and (max-width: 1300px) {
    padding-right: 0px;
  }
  @media only screen and (max-width: 800px) {
    padding-right: 0px;
  }
}
