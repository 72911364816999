/*Footer*/
.footer {
  background-color: #f9fafb;
  padding: 50px 0px 50px 0px;
  text-align: center;
}

.footer .footer-logo {
  display: block;
  margin: 20px auto 20px auto;
}

.footer .copyright {
  color: rgb(9, 46, 76);
  text-align: center;
}

.footer .footer-nav {
  margin: 40px 0 20px 0;
}

.footer .footer-nav a {
  text-decoration: none;
  color: rgb(9, 46, 76);
  font-size: 16px;
  font-weight: 500;

  margin: 0 10px 0 10px;
  @media (max-width: 650px) {
    margin: 5px 0px;
    display: flex;
    flex-direction: column;
  }
}

.footer .social-icons {
  margin: 20px 0 20px 0;
  font-size: 50px;
}

.footer .social-icons svg {
  margin-left: 20px;
  margin-right: 20px;
  color: $turquoise;
  transform: scale(1.5);
}
