@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*Primary Styles*/

.content {
  overflow: hidden;
  max-width: calc(#{$maxWidth} + 80px);
  margin: auto;
  padding: 40px 10px;
  @media (min-width: $md) {
    padding: 40px;
  }
}

.max-width {
  max-width: $maxWidth;
}

.no-padding {
  @media (min-width: $maxWidth) {
    padding: 0 !important;
  }
}

.center {
  margin: 0 auto;
  text-align: center;
  > * {
    text-align: center;
  }
}

body {
  background-color: white;
  font-family: "Poppins", sans-serif;
  color: #626369;
  position: absolute;
  width: 100%;
  overflow: inherit;
  /* MUI select fix to stop the page jumping  */
  // width: calc(100vw - 19px);
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
}

/*Typography*/
h1 {
  color: #242f51;
  font-size: 58px;
  font-weight: 600;
  line-height: 75px;
}

h2 {
  color: #242f51;
  font-size: 34px;
  font-weight: 600;
  line-height: 46px;
}

h3 {
  color: #616368;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}

h3 {
  color: #616368;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}

button span {
  font-size: 15px;
  font-weight: 700;
}

.fullwidth-button {
  width: 100%;
}

.category-heading {
  color: $turquoise;
  font-size: 24px;
}

.MuiButtonBase-root.theme-button {
  border-radius: 2em;
  margin-top: 1em;
  padding: 10px 30px 10px 30px;
  background-color: $orange;
  color: white;
  border: 1px solid $orange;
  font-size: 16px;
}

.MuiButtonBase-root.theme-button:hover {
  background-color: white;
  color: $orange;
  border: 1px solid $orange;
}

.MuiButtonBase-root.theme-button.outline {
  border-radius: 2em;
  margin-top: 1em;
  padding: 10px 30px 10px 30px;
  font-size: 16px;
  background-color: white;
  color: $orange;
  border: 1px solid $orange;
}

.MuiButtonBase-root.theme-button.outline:hover {
  background-color: $orange;
  color: white;
  border: 1px solid $orange;
}

.PrivateTabIndicator-colorSecondary-12 {
  background-color: #626369;
}

.PrivateTabIndicator-root-10 {
  height: 3px;
}

.highlight {
  margin: 30px 0 30px 0;
}

.highlight p {
  margin: 10px 0 10px 0;
}

.highlight-orange {
  color: $orange;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.dot-orange {
  width: 100px;
  height: 100px;
  background-color: $orange;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0 10px 0;
  opacity: 0.2;
}

.highlight-blue {
  color: $blue;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.dot-blue {
  width: 100px;
  height: 100px;
  background-color: $blue;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0 10px 0;
  opacity: 0.2;
}

.highlight-teal {
  color: $turquoise;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.dot-teal {
  width: 100px;
  height: 100px;
  background-color: #16b7c6;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0 10px 0;
  opacity: 0.2;
}

.page-loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin: 0;
  }
}

.clickable {
  cursor: pointer;
}
