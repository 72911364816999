.header {
  background-color: transparent !important;
  box-shadow: none;

  &__top-banner {
    display: none;
    @media (min-width: $md) {
      display: block;
      min-height: 29px;
      background-color: #092e4c;
      content: "";
      width: 100%;
    }
    &__content {
      max-width: $maxWidth;
      margin: 0 auto;
      padding: 4px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: white;

      a {
        text-decoration: none;
        color: inherit;
        font-size: 14px;
        font-weight: 500;
      }
      .icon {
        font-size: 19px;
        margin-left: 7px;
      }
    }
  }

  &__toolbar {
    min-height: 92px !important;

    @media (min-width: $md) {
      min-height: 92px !important;
      margin: 10px 0 !important;
    }
  }

  &__logo {
    height: 36px;

    @media (min-width: $lg) {
      height: 47px;
    }
  }

  &__link {
    text-transform: capitalize !important;
    color: $navy;
    font-size: 18px;

    &:hover {
      background-color: transparent !important;
      color: $orange !important;
    }
    &:not(:first-child) {
      margin-left: 35px !important;
    }
  }

  &__link--active {
    text-transform: capitalize !important;
    color: $lightBlue;
    font-size: 18px;

    &:hover {
      background-color: transparent !important;
      color: $orange !important;
    }
    &:not(:first-child) {
      margin-left: 35px !important;
    }
  }
}
.menu-wrapper {
  a {
    text-align: end;
    display: block;
    padding-right: 1.5em;
  }

  hr {
    border-color: hsla(195, 35%, 91%, 0.44);
  }

  li {
    float: left;
  }
}

a.contact {
  background-color: $lightBlue;
  &:hover {
    background-color: #ddb228;
  }
}
