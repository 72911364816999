.banner {
  background-color: $orange !important;
  color: white;
  margin: 0;
  padding: 15px 0 !important;
}

.banner p {
  font-weight: 300;
  margin: 0;
  font-size: 0.875rem !important;
}

.banner span {
  background-color: white;
  border-radius: 4px;
  padding: 5px 15px;
  color: $orange !important;
  font-weight: 600;
  display: inline-block;
  margin-right: 20px;
}
