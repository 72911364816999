.contact-us {
  &__form-container {
    padding: 50px 0;
    min-height: 50vh;
    margin: 0 0;
    @media (min-width: $md) {
      max-width: 50%;
      padding: 50px 40px;
    }
  }
  .full-stop {
    color: $orange;
  }
  h1 {
    margin: 0;
    line-height: normal;
    padding: 0;
    font-size: 32px;
    text-align: center;
    @media (min-width: $md) {
      font-size: 58px;
      text-align: left;
    }
  }
  h2 {
    font-size: 20px;
    margin: 0;
  }
  h5 {
    color: $orange;
    line-height: 1.4;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .main-image {
    display: block;
    height: auto;
    margin: 20px auto;
    width: 40%;
    @media (min-width: $md) {
      width: 80%;
    }
  }
}

.fieldWrapper > div > div {
  &:after {
    border-color: $orange;
    width: 100%;
  }
  &::before {
    border-color: #626369;
    animation: none;
    transition: none;
    width: 100%;
  }
}

.selectWrapper > div > div {
  background-color: white !important;
}
.selectWrapper {
  .MuiInput-underline:before {
    border-bottom: 1px solid #626369;
  }
  .MuiInput-underline::after {
    border-bottom: 1px solid #626369;
  }
}

p {
  color: #626369;
}

.sign-up-to-newsletter {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .subscribe-btn {
    border-radius: 2em;
    margin-top: 1em;
    padding: 10px 30px 10px 30px;
    background-color: #ff725c;
    color: white;
    border: 1px solid #ff725c;
    font-size: 16px;
  }
  h1 {
    font-size: 2.125rem;
    color: #ff725c;
    margin: 0;
    line-height: normal;
    text-align: center;
  }
  h2 {
    font-size: 1.6rem;
    margin: 0;
  }
  .even-columns {
    display: flex;
    flex-direction: column;

    @media (min-width: $md) {
      flex-direction: row;
    }
  }
  .even-columns > * {
    margin: 1rem;
  }
  .contact-us__form-container {
    padding: 0;
    min-height: 0;
  }
}
