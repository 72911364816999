.aa-Autocomplete,
.aa-Detached {
  --aa-input-border-color-rgb: 255, 114, 92;
  --aa-primary-color-rgb: 255, 114, 92;

  position: relative;
  width: 100%;

  .aa-Form {
    border-radius: 50px;
  }

  .aa-DetachedSearchButton {
    border-radius: 50px;
  }
}

.aa-Panel {
  --aa-panel-border-color-rgb: 255, 114, 92;

  border-radius: 8px;
  border: 1px solid rgb(var(--aa-panel-border-color-rgb));

  .all-companies-button {
    padding: 8px 6px;
    border-radius: 0;
    margin-top: 2px;
  }
}

.ais-InfiniteHits-item {
  padding: 0;
  margin: 1rem 0;

  * {
    flex: 1;
  }

  .featured {
    border: 1px solid #ffcdc5;
  }
}

.ais-SearchBox {
  .dl-input {
    border-color: $orange;
    border-radius: 50px;
    box-shadow: none;

    &::placeholder {
      color: rgba(38, 38, 39, 0.4);
    }
  }
}
