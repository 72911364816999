/*Hero Section*/
.hero {
  background-image: url(../images/mountains-bg.png);
  background-size: cover;
  background-position: center center;
  min-height: 60vh;

  > div {
    max-width: calc(#{$maxWidth} + 80px);
    margin: 0px auto;
  }
  &__title {
    margin-bottom: 40px;
    margin-top: 20px;
    font-size: 2rem;
    line-height: 1.4;
    @media (min-width: $md) {
      font-size: 38px;
      line-height: 53px;
    }
  }

  &__image {
    display: block;
    height: auto;
    margin: 20px 0;
    width: 80%;
    @media (min-width: $md) {
      margin: auto;
    }
  }
}

.heroTitleContainer {
  font-display: swap;
  @media only screen and (max-width: 800px) {
    min-height: 130px;
  }
}

.ribbon {
  margin-top: 40px;
  svg {
    background-color: $orange;
    color: white;
    padding: 5px;
    border-radius: 50%;
    vertical-align: middle;
    position: relative;
    z-index: 999;
  }
  &__text {
    font-size: 0.875rem;
    color: #242f51;
    padding: 3px 30px 3px 40px;
    border-radius: 20px;
    vertical-align: middle;
    margin-left: -25px;
    position: relative;
    z-index: 99;
    @media (min-width: $sm) {
      background-color: #e2eaed;
    }

    @media (min-width: $lg) {
      font-size: 1.125rem;
      background-color: #e2eaed;
    }
  }
}

.home-content-section {
  padding: 2em 0 3em 0;
  border-bottom: 1px solid #d5d5d5;

  @media (max-width: 960) {
    align-items: center;
  }

  &:last-child {
    border-bottom: 0;
  }

  &__benefits {
    h2 {
      margin: 10px 0;
      text-align: center;
    }
    &__subHeader {
      margin-bottom: 40px !important;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    h3 {
      margin: 30px auto 20px;
      width: 90%;
      text-align: center;
    }
    p {
      margin: 0 auto 40px;
      width: 90%;
      text-align: center;
    }
    @media (min-width: $md) {
      margin: 0px auto 0px;
    }
  }
  .contact-form-header {
    margin-top: 40px;
  }

  .contact-form-container {
    border: 1px solid red;
    margin: 40px 0;
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .theme-button {
      margin-top: 40px;
    }
  }
}

@media only screen and (min-width: 960px) {
  .company-link:hover {
    margin: 20px 0;
  }
}

.trusted-companies-section {
  margin: 60px 0;
  padding: 40px 0;
  border-top: 1px solid #e4e4e4;
}

.claim-presence,
.claim-presence h2,
.small-heading,
.why-choose h2,
.centered-body {
  text-align: center;
}

.search-field {
  max-width: 600px;
}

.search-results .search-field,
.search .search-field {
  background-color: white;
  border: 1px solid $orange;
  border-radius: 50px;
}

.inline-grid {
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
}

.subcategory-list-item {
  list-style: none;
  padding-left: 0;

  a {
    &:hover {
      color: $turquoise;
    }
  }
}

.leading-providers {
  .container {
    padding: 2rem;
  }
}

.provider-logo {
  display: block;
  height: 150px;
  width: auto;
}

/*Search Component*/
.MuiButtonBase-root.search-button {
  display: none;
}

.claim-presence {
  .highlight {
    width: 100%;
    min-height: 100px;
    div,
    h3 {
      text-align: center;
    }
  }
  .benefits-column {
    @media (max-width: $md) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

/* Autocomplete styles */

.MuiAutocomplete-popperDisablePortal {
  margin-top: 60px;
  border-radius: 8px;
}
.MuiAutocomplete-paper {
  border: 1px solid $orange;
  border-radius: 8px;
  box-shadow: 0 6px 24px 0 rgb(24 24 24 / 16%);

  &:empty {
    border: 0;
  }
}

.HeroImageContainer {
  margin: auto;
  @media only screen and (min-width: 300px) {
    min-height: 160px;
    height: 160px;
    min-width: 230px;
    width: 230px;
  }

  @media only screen and (min-width: 350px) {
    min-height: 230px;
    height: 230px;
    min-width: 300px;
    width: 300px;
  }

  @media only screen and (min-width: 450px) {
    min-height: 300px;
    height: 300px;
    min-width: 400px;
    width: 400px;
  }
  @media only screen and (min-width: 600px) {
    min-height: 370px;
    height: 370px;
    min-width: 500px;
    width: 500px;
  }
  @media only screen and (min-width: 960px) {
    min-height: 300px;
    height: 300px;
    min-width: 400px;
    width: 400px;
  }
  @media only screen and (min-width: 1100px) {
    min-height: 360px;
    height: 360px;
    min-width: 500px;
    width: 500px;
  }
}
