.slider__container {
  height: 232px;
  overflow: hidden;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .handle {
    background-color: white;

    font-size: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #ff725c;
    padding: 0;
    height: 232px;

    border: none;
    transition: color 150ms ease-in-out;
    &--left {
      svg {
        transform: rotate(90deg);
      }
    }
    &--right {
      svg {
        transform: rotate(270deg);
      }
    }
  }
  &:hover {
    .handle {
      color: #ff725c;
    }
  }
}

.swiper-slide {
  padding: 15px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.swiper-button-next {
  color: #ff725c !important;
}
.swiper-button-prev {
  color: #ff725c !important;
}

.mask {
  overflow-x: hidden;
  min-width: calc(100% - 48px);
}

.slider {
  --slider-index: 0px;
  display: flex;

  justify-items: center;
  align-items: center;
  height: 232px;
  transform: translateX(var(--slider-index));
  transition: transform 250ms ease-in-out;

  &__item {
    height: 232px;

    min-width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

.featured-companies {
  &__header {
    div {
      max-width: 70%;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    h2 {
      margin-bottom: 0;
    }
    @media (min-width: $md) {
      flex-direction: row;
    }
    a {
      color: $turquoise;
      text-decoration: underline;
    }
  }
  &__logos {
    div {
      min-height: 160px;
      margin-top: 20px;
      @media (min-width: $md) {
        margin-top: 0px;
      }
    }
  }
  &__container {
    // min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    img {
      border: 1px solid rgb(255, 114, 92);
      box-shadow: rgba(255, 114, 92, 0.3) 0px 5px 15px;
      // height: 161px;
      margin: auto;
      display: block;
      border-radius: 50%;
      border: 1px solid #d5d5d5;
      transition: height 250ms ease-in-out;
      // @media (min-width: $md) {
      height: 121px;

      @media only screen and (min-width: 960px) {
        :hover > & {
          border: 1px solid rgb(255, 114, 92);
          box-shadow: rgba(255, 114, 92, 0.3) 0px 5px 15px;
          height: 161px;
          margin-top: 40px;
        }
      }
    }
    .padding {
      padding: 15px;
    }
    .name {
      display: none;
      color: white;
      position: relative;
      margin-top: 15px;
      text-align: center;
      font-size: 14px;
      border-radius: 12px;
      padding: 0px 15px;
      width: fit-content;
      background-color: #242f51;

      // @media (min-width: $md) {
      background-color: transparent;

      @media only screen and (min-width: 960px) {
        :hover > & {
          display: block;
          background-color: #242f51;
        }
      }
    }

    .name:after {
      border-color: rgba(36, 47, 81, 0);
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(36, 47, 81, 0);
      border-bottom-color: #242f51;
      border-width: 6px;
      margin-left: -6px;
      @media (min-width: $md) {
        border-color: transparent;
        :hover > & {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(36, 47, 81, 0);
          border-bottom-color: #242f51;
          border-width: 6px;
          margin-left: -6px;
        }
      }
    }
  }

  .secondary-title {
    margin-bottom: 0px;
  }
  .third-title {
    margin-top: 40px;
    margin-bottom: 0px;
    text-align: left;
  }

  .MuiPaper-root ul img,
  .trusted-companies {
    margin: auto;
    display: block;
  }
  .MuiPaper-root ul h3 {
    text-align: center;
  }
}

.swiper-wrapper {
  height: 250px !important;
  min-height: 250px !important;
}

.caruselImageWrapper {
  height: 100%;
  flex-direction: column;
  display: flex;
  user-select: none;
  max-width: 100px;
  max-height: 100px;
}
.caruselImage {
  max-inline-size: 100%;
  block-size: auto;
  border-radius: 100%;
  border: 1px seashell solid;
}
