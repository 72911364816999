.dev-console {
  top: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: green;
  border: 1px solid black;
  padding: 10px 0;

  &__logs {
    pre {
      display: inline;
      font-size: 1.125rem;
    }
    span {
      padding-right: 20px;
    }
  }

  .ok {
    color: green;
  }
  .warning {
    color: orange;
  }
  .danger {
    color: red;
  }
}
