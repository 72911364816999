/*Details page*/

.company-details-header {
  &__background {
    background-image: url(../images/mountains-bg.png);
    background-size: cover;
    background-position: center center;
    min-height: 250px;
  }

  &__company-name {
    width: 100%;
    margin: -64px auto 0;
    padding: 0 5px;
    max-width: calc(1310px + 80px);
    @media (min-width: $sm) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  &__name {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 15px;

    h2 {
      margin: 0 0 15px 0;
    }
    p {
      margin: 0;
    }
    @media (min-width: $sm) {
      height: 125px;
      margin-top: 0;
      p {
        line-height: 62px;
      }
    }
  }

  &__logo {
    height: 125px;
    width: 125px;
    background: white;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #e3eff6;
    margin: 0 20px 0 0;

    &--placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.breadcrumbs {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;

  .content__breadcrumbs {
    max-width: calc(1310px + 80px); // margin: 0;
    padding: 5px 5px;
    @media (min-width: $sm) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.container-box {
  padding-top: 10px;

  .contact-form-container {
    margin: 0 auto;
    padding: 0 10px;
  }

  .contact-form-header {
    margin: 40px 0;
  }
}

.details-content {
  padding: 40px 5px;
  .details-row {
    margin-top: 8px;
    margin-bottom: 8px;

    // &:last-child {
    //   margin-bottom: 30px;
    // }
  }
  @media (min-width: $sm) {
    padding-left: 40px;
    padding-right: 40px;
  }

  .item {
    background-color: #f5f7fa;
    padding: 1rem 2rem;
    height: 100%;
    border-radius: 10px;
  }

  .breadcrumbs {
    margin-bottom: 40px;
    display: block;

    &__current-path {
      display: none;
    }

    @media (min-width: $sm) {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &__current-path {
        display: flex;
      }
    }
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px 0 60px;

  .main {
    font-weight: 600;
    display: block;
    margin-bottom: 15px;
    text-align: center;
  }

  .reason {
    font-weight: 400;
    font-size: 14px;
    max-width: 48ch;
    text-align: center;
  }
}

/*Company Details Tabs*/

header.nav-tabs {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 2px solid #16b7c6;
  padding-bottom: 5px;

  .MuiTabs-indicator {
    height: 0px;
    background-color: transparent;
  }
  .MuiButtonBase-root {
    background-color: #e9ecf0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .MuiButtonBase-root.Mui-selected {
    background-color: #dbeff8;
  }
  .MuiButtonBase-root span {
    color: #6e768f;
    text-transform: none;
    padding: 0px 10px 0px 10px;
  }
  .MuiButtonBase-root.Mui-selected span {
    color: #242f51;
  }
  .MuiButtonBase-root {
    margin-right: 8px;
  }
  .MuiButtonBase-root:last-child {
    margin-right: 0px;
  }
}

.nav-tabs {
  .tab--accent {
    background-color: $orange !important;
    span {
      color: white !important;
    }
  }
}

// .company-logo-header {
//   h2 {
//     margin: 0;
//     font-size: min(34px, 24vw);
//     @media (min-width: $md) {
//       margin: 0 0 40px 0 !important;
//     }
//   }
// }

.company-details-information {
  .about {
    p {
      margin-left: 0;
    }
  }
  h3 {
    color: #242f51;
    font-weight: 500;
    text-transform: capitalize;
    margin: 10px 0 15px;
  }
  .dcm_axis {
    margin: 20px;
  }
  .location-container {
    &__card {
      height: 100%;
      background-color: white;
      padding: 20px 20px;
      ul {
        text-align: center;
      }
    }
    div {
      min-height: calc(100% - 2rem);
    }
  }

  a {
    color: $darkTurquoise;
    &:hover {
      color: $turquoise;
    }
  }

  &.info {
    display: flex;
    flex-direction: row;
    > div {
      width: 100%;
    }
  }
  &.locations {
    margin-top: 16px;
  }
  &.details {
    p {
      margin-top: 10px;
      margin-left: 40px;
      margin-bottom: 30px;
    }
    p:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  &.videos {
    margin: 8px 0;
    background-color: #e3efff;
    .video-container {
      border-radius: 5px;
      overflow: hidden;
      height: 500px;
      margin-bottom: 10px;
    }
    .video-container-small {
      border-radius: 5px;
      overflow: hidden;
      height: 160px;
      margin-bottom: 10px;
    }
  }

  // &.geographic,
  // &.stock-chart,
  // &.related-companies {
  //   margin: 8px 0;
  //   padding: 2rem;
  // }

  &.info-1 {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.info-2 {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .features__icons-integrated-standalone {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .features__icon-standalone,
  .features__icon-integrated {
    height: 15px;
    padding-right: 10px;
  }

  &__resource-link {
    p {
      display: none;
    }
  }

  &__resource-link:hover {
    border: 1px solid $turquoise;
    display: block;
    border-radius: 4px;

    h3,
    p {
      display: block;
      color: $turquoise;
    }
  }

  &__premium-account-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    min-height: 50vh;

    h3 {
      margin-bottom: 0;
    }
  }
}
.company-details-information.highlights h3 {
  margin-bottom: 0px;
}

.company-details__linkedIn-logo {
  height: 20px;
}

.staff-profiles {
  &__card {
    height: 100%;
    background-color: white;
    padding: 20px 0;
  }
  .staff-heading {
    font-weight: 500;
    margin: 0px;
    text-align: center;
    @media (min-width: $sm) {
      text-align: center;
    }
  }

  .staff-heading.job-title {
    color: #16b7c6;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    max-width: 21ch;
    line-height: 1.3;
    margin: 0 auto;
  }
  .staff-profile-image {
    margin-bottom: 20px !important;
    border-radius: 5px;
    @media (min-width: $md) {
      max-width: 100%;
    }
  }
}

.contact-company {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  h3 {
    color: $orange;
  }
}

.contact-us {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__form-container {
    padding: 50px 0;
    min-height: 50vh;
    margin: 0 0;
    margin: 0 auto;

    p {
      text-align: center;
      margin: 20px 0 !important;
    }
    .fieldWrapper {
      width: 100%;
    }
    button {
      display: inline-block;
      margin: 0 auto;
      width: 100%;
    }
    .theme-button {
      margin-top: 40px;
    }

    @media (min-width: $md) {
      max-width: 50%;
      padding: 50px 40px;
      button {
        width: 50%;
      }
    }
  }
}

element.style {
}

.fieldWrapper > div > div {
  &:after {
    border-color: $orange;
    width: 100%;
  }
  &::before {
    border-color: #626369;
    animation: none;
    transition: none;
    width: 100%;
  }
}

.highlight-data {
  p {
    margin-top: 0;
    margin-left: 50px;
  }
  h3 {
    margin-top: 10px;
    margin-bottom: 3px;
  }
}
// .locat .locations .MuiPaper-Root {
//   height: 100%;
//   overflow: hidden;
//   padding-left: 20px;
//   padding-right: 20px;
//   padding-top: 0px;
//   padding-bottom: 0px;
// }

.locations {
  padding-top: 8px;
}

.locations ul,
.news ul,
.staff ul {
  padding-left: 0px;
}

.locations ul li,
.news ul li,
.staff ul li {
  display: block;
}

.locations img,
.news img,
.staff img {
  margin: auto;
}

/*Updates 020921*/

/*Overview*/
.overview-icon {
  border-radius: 50%;
  padding: 5px;
  color: white;
  margin-right: 10px;
  vertical-align: middle;
  // font-size: 0.9em;

  &.teal {
    background-color: #17d3c5;
  }

  &.blue {
    background-color: #2a3e7b;
  }

  &.light-blue {
    background-color: #1a96b6;
  }

  &.purple {
    background-color: #9288cc;
  }

  &.orange {
    background-color: #ff725e;
  }

  &.yellow {
    background-color: #e0c62d;
  }

  &.grey {
    background-color: #9a9b9f;
  }
}

/*Locations*/

.locations {
  .city {
    color: black;
    font-weight: 500;
  }
  .country {
    color: black;
    font-weight: 700;
    padding-bottom: 20px;
  }
  .location-address {
    margin-top: 10px;
    font-size: 0.9em;
  }
  li {
    color: #9a9b9f;
    font-weight: semi-bold;
  }
}

/*Related Companies*/

.related-companies .company-heading.industry {
  color: #16b7c6;
}

.related-companies .company-heading {
  font-weight: 500;
  margin: 0px;
}

/*Data Capability*/
.inset-row {
  background-color: white;
}

.dcm-list {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    text-transform: capitalize;
  }

  .dcm-icon {
    border-radius: 50%;
    padding: 2px;
    color: white;
    margin-right: 10px;
    vertical-align: middle;
    font-size: 0.9em;
  }

  .available {
    .dcm-icon {
      background-color: #16b7c6;
    }
  }

  .not-available {
    color: #aec1ca;
    .dcm-icon {
      background-color: #d7dbdc;
    }
  }
}

.inset-row .MuiGrid-item {
  padding: 0px 30px 30px 30px;
}

.MuiTableContainer-root {
  box-shadow: none;
}

/*Resources*/
.news-image {
  width: 100%;
  display: none;
  @media (min-width: $md) {
    display: block;
  }
}

.news .MuiPaper-root {
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-align: center;
  }

  @media (min-width: $md) {
    flex-direction: row;
    h3 {
      text-align: left;
    }
  }
}

.news .MuiPaper-root .MuiGrid-item {
  vertical-align: middle;
}

.get-in-touch {
  margin: 2rem 0 0;
  a {
    text-decoration: underline;
    color: $turquoise;
  }
}

.no-content {
  padding: 0px 20px 20px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e4e4e4;

  @media (min-width: $md) {
    padding: 0px 0 20px;
  }
}

.customer-references {
  max-width: 900px;
  margin: 40px auto 40px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &__reference {
    // border: 1px solid grey;
    background-color: white;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;
    min-height: 200px;
    border-radius: 4px;
    text-align: center;
    // width: 200px;
    // display: ;
  }
  svg {
    fill: #fec458;
    // width: fit-content;
    // height: fit-content;
    margin: 10px 0 0px;
    // outline: 1px solid red;
  }
}
