.login {
  margin: 80px auto 60px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  border: 1px solid #dedede;
  border-radius: 4px;
  min-height: 40vh;
  padding: 40px;

  img {
    height: 35px;
  }

  h2 {
    margin: 20px 0;
    font-size: 1.275rem;
  }

  &__form {
    margin: 20px 0;
    width: 100%;
  }

  .results h4 {
    text-transform: none;
    color: inherit;
    font-size: 22px;
  }

  a {
    color: inherit;
    font-weight: 600;
  }

  .MuiAlert-message {
    color: $darkTurquoise;
    font-size: 12px;
  }

  .emailField {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0.875rem 0 20px;
    padding: 0 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d0cdcd;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .reset-link {
    margin: 3rem 0 2rem;
    text-align: center;

    a {
      font-weight: normal;
      font-size: 14px;
    }
  }

  .emailError {
    margin: 7px 0;
    color: red;
    // background-color: #f8d7da;
    // border-color: #f5c6cb;
    font-size: 0.875rem;
    padding: 0rem 0rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .logInError {
    margin: 30px 0;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .btn {
    background-color: #038097;
    border: 1px solid #038097;
    color: white;
    padding: 0.8em 1.2em;
    font-size: 1rem;
    margin-top: 15px;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .btn--login {
    width: 100%;
  }

  .btn-disabled {
    background-color: rgba(3, 128, 151, 0.42) !important;
    border-color: rgba(3, 128, 151, 0.42) !important;
  }

  p {
    max-width: 500px;
  }

  .back-btn {
    margin-top: 40px;
  }
}
