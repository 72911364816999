.admin-app {
  display: flex;

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 20px;
      line-height: normal;
    }

    .btn--new {
      font-size: 14px;
      height: 32px;
      padding: 0 12px;
      margin-left: 20px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  .sidebar {
    width: 240px;
    padding: 0;
    display: block;
    background-color: hsla(0, 0%, 98%, 1);
    border-right: 1px solid hsla(220, 5%, 89%, 1);

    &__padding {
      display: block;
      min-height: 100vh;
      max-height: 100vh;
      justify-content: space-between;
      padding: 40px 16px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 40px);
    }
    .company-logo {
      height: 30px;
    }

    .username {
      font-size: 12px;
      display: block;
      font-weight: 600;
    }
    .role {
      font-size: 14px;
      text-transform: uppercase;
      display: block;
    }

    .link {
      font-size: 14px;
      font-weight: 600;
    }

    ul {
      list-style-type: none;
      margin: 20px 0;
      padding: 0;

      a {
        margin: 10px 0;
        border-radius: 7px;
        padding: 4px 16px 3px 16px;
        width: 100%;
        display: block;
      }
      a.active {
        background-color: hsla(189, 96%, 30%, 0.2);
        color: hsla(189, 96%, 30%, 1);
        weight: 600;
      }
      .nav-link {
      }
    }

    .separator {
      background-color: rgb(226, 227, 229);
      margin: 20px 0px;
      height: 1px;
    }
  }

  .admin-content {
    display: block;
    height: 100%;
    width: calc(100vw - 240px);
    margin: 0;
    padding: 24px 40px 0;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;

    .new-account {
      // margin: 80px auto 60px;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 60vh;
      border-radius: 4px;
      min-height: 40vh;
      padding: 40px;

      h2 {
        margin: 20px 0;
        font-size: 1.275rem;
      }

      &__form {
        margin: 20px 0;
        width: 100%;
      }

      .results h4 {
        text-transform: none;
        color: inherit;
        font-size: 22px;
      }

      a {
        color: inherit;
        font-weight: 600;
      }

      .MuiAlert-message {
        color: $darkTurquoise;
        font-size: 12px;
      }

      .emailField {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin: 0.875rem 0 20px;
        padding: 0 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d0cdcd;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

      .reset-link {
        margin: 3rem 0 2rem;
        text-align: center;

        a {
          font-weight: normal;
          font-size: 14px;
        }
      }

      .emailError {
        margin: 7px 0;
        color: red;
        // background-color: #f8d7da;
        // border-color: #f5c6cb;
        font-size: 0.875rem;
        padding: 0rem 0rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
      }
      .logInError {
        margin: 30px 0;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
      }

      .btn--admin {
        // background-color: #038097;
        // border: 1px solid #038097;
        // color: white;
        // padding: 0.8em 1.2em;
        text-transform: capitalize;
        font-size: 14px;
        margin-top: 15px;
        border-radius: 0.25rem;
        cursor: pointer;
      }

      .btn--admin--confirm {
        background-color: rgba(3, 128, 151, 1) !important;
        border-color: rgba(3, 128, 151, 1) !important;
        color: white;
      }
      .btn--admin--cancel {
        // border-color: rgba(3, 128, 151, 0.42) !important;
      }

      p {
        max-width: 500px;
      }

      .back-btn {
        margin-top: 40px;
      }
    }
    .form {
      margin: 20px 0;
      width: 100%;
    }

    .MuiTab-root {
      text-transform: capitalize;
    }

    .MuiTabs-flexContainer {
      border-bottom: 1px solid hsla(220, 5%, 89%, 1);
    }

    .MuiTableHead-root {
      th {
        background-color: transparent;

        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        color: hsla(223, 3%, 41%, 1);
      }
    }
  }

  .all-companies {
    margin: 0 auto 50px;
    &__table {
      overflow-x: scroll;

      &__container {
        width: 100%;
        height: 700px;
        overflow: auto;
      }
    }

    ul {
      width: 100%;
    }
    li {
      padding: 0.225rem 0;
    }
    .disabled {
      // color: grey;
    }
    table {
    }
    th {
      background-color: #fafafa;
      font-weight: 600;
      font-size: 12px;
    }
    th.list {
      min-width: 200px;
    }
    th.name {
      min-width: 130px;
    }
    tr {
      vertical-align: top;
      .edit {
        display: none;
      }
      &:hover {
        background-color: rgb(239, 246, 252);
        .edit {
          display: block;
        }
      }
      td {
        font-size: 12px;
      }
    }

    .preview {
      color: #16b7c5;
    }

    .listings {
      margin: 0;
      padding: 0 0 0 20px;
      // list-style-type: none;
      li {
        padding: 0 5px 0 5px;
        margin: 0 5px 5px 0;
        // background-color: #f2f2f2;
        // width: fit-content;
        font-size: 12px;
      }

      .invalid {
        color: red !important;
      }
    }

    .state-circle {
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      margin-top: 5px;
      margin-right: 5px;
      display: inline-block;
      width: 8px !important;
      height: 8px !important;
      transition: background-color linear 0.2s;

      &--active {
        background-color: rgb(51, 153, 51);
        border-color: rgb(51, 153, 51);
      }

      &--new-update {
        background-color: rgb(219, 137, 67);
        border-color: rgb(219, 137, 67);
      }
      &--inactive {
        background-color: rgb(178, 178, 178);
        border-color: rgb(178, 178, 178);
      }
    }
  }

  a {
    cursor: pointer;
  }
}

.log-in {
  margin: 40px 0 80px;
  max-width: 500px;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  text-align: center; */

  h1 {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0;
  }

  h5 {
    margin: 40px 0;
  }

  h5,
  .questions h5 {
    font-size: 1rem;
  }
}

.new-registration {
  margin: 40px auto 80px;
  max-width: 600px;
  min-height: calc(100vh - 618px);
  padding: 0 20px;

  a {
    text-decoration: underline;
    color: #16b7c5;
  }
  .form {
    width: 100%;

    .section {
      counter-reset: section; /* Set a counter named 'section', and its initial value is 0. */
    }
    p.section__header {
      background-color: #038097;
      padding: 20px 40px;
      color: white;
      width: 680px;
      margin-left: -40px;
      font-size: 32px;
      font-weight: 5400;
    }
    h1 {
      color: rgb(0, 61, 114);
      font-size: 21px;
      font-weight: 500;
      border-bottom: 1px solid rgb(0, 61, 114);
    }
    h2 {
      font-size: 1.125rem;
      font-weight: 500;
      color: rgb(51, 51, 51);
    }
    h2::before {
      counter-increment: section; /* Increment the value of section counter by 1 */
      content: counter(section) ". "; /* Display the word 'Section ', the value of
                                                       section counter, and a colon before the content
                                                       of each h3 */
    }
    &__field {
      margin: 40px 0;
      h3 {
        color: #797981;
        margin: 20px 0 0;
        font-weight: 500;
        font-size: 1rem;
        background-color: #e9e9e9;
        padding: 0.5rem 1rem;
        // border-top: 1px solid #e9e9e9;
        // border-bottom: 1px solid #e9e9e9;
      }
      .disabled {
        color: rgb(143, 142, 142);
      }
      &__checkbox-columns {
        display: flex;
        div {
          width: 100%;
        }
      }
      &__features {
        display: flex;

        &__column {
          display: flex;
          flex-direction: column;
          min-width: 20%;
          border-right: 1px solid #e9e9e9;
          justify-content: flex-start;

          &:first-child {
            width: 100%;
            align-items: flex-end;
          }
          &:not(:first-child) {
            align-items: center;
          }

          div {
            min-height: 50px;
            display: flex;
            align-items: center;
          }
        }
        &__row {
          border-bottom: 1px solid #e9e9e9;
          width: 100%;
          margin: 0 auto;
          justify-content: center;
        }
        &__row-label {
          justify-content: flex-start;
        }
      }
      .geographic-segment {
        margin-bottom: 20px;
      }
      &__geographic-revenue-segmentation {
        svg {
          margin-left: 20px;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .new {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .header-note {
        font-size: 0.875rem;
        font-weight: normal;
        color: #038097;
      }
    }

    &__buttons {
      margin: 20px 0 40px;
      padding-top: 40px;
      border-top: 1px solid #e4e4e4;
    }

    &__button--submit {
      background-color: $darkTurquoise;
    }

    &__input--video,
    &__input--location {
      margin: 10px 0;
      &--city,
      &--country {
        width: 270px;
      }
      &--country {
        margin-left: 20px;
      }
    }
    &__button--add-item,
    &__button--remove-item {
      cursor: pointer;

      display: inline-block;
      width: fit-content;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      font-size: 0.875rem;
      margin: 10px 10px 0 0;
      padding: 0.175rem 0.7rem;
      background-color: #f8f8f8;
    }

    &__button--add-video {
    }
    &__button--remove-video {
    }
  }
  .admin-section {
    width: 100%;
    margin: 40px 0 0;
    padding: 20px 40px;
    border-top: 1px solid rgba(172, 171, 171, 0.5);
    border-bottom: 1px solid rgba(172, 171, 171, 0.5);
    background-color: #fafafa;

    h1 {
      font-size: 1.125rem;
      border: 0;
      color: grey;
      line-height: normal;
    }
  }
}

.confirmation-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-user-edit {
  background-color: #fbf3e1;
}
.not-approved {
  background-color: #fafafa;
}

.MuiChip-colorPrimary {
  background-color: $orange;
}
.MuiChip-colorSecondary {
  background-color: #038097;
}

.MuiChip-sizeSmall {
  margin: 0 5px;
  font-size: 10px;
}

.partner-account {
  max-width: 600px;
  margin: 0 auto;
  height: 100vh;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    h2,
    p {
      text-align: center;
      font-size: 16px;
      margin: 0;
    }

    h2 {
    }
  }
}
.instructions {
  ul {
    margin: 20px 0 0 10px;
    padding: 0 0 0 10px;
  }

  h4 {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eaeaea;
  }
  li {
    margin: 0 0 20px 0;
    padding: 0;
  }
}
