.about-marketplace {
  h3 {
    margin: 60px 0 20px;
  }
  &__benefits {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    gap: 5px;
  }
}
.tick {
  color: #16b7d0;
}

.ribbonWrapper {
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
}

.spacer--large {
  height: 180px;
  @media only screen and (max-width: 800px) {
    display: none;
  }
}
.spacer--small {
  height: 40px;
  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.boxContainer {
  display: flex;
  justify-content: center;
  gap: 130px;

  @media only screen and (max-width: 800px) {
    gap: 50px;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.box {
  display: flex;
  flex-direction: column;
  text-align: center;

  width: 600px;
  padding: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
}

.boxTitleWrapper {
  height: 30px;
  @media only screen and (max-width: 960px) {
    height: 70px;
  }
  @media only screen and (max-width: 600px) {
    height: auto;
  }
}

.benefitsContainer {
  margin-top: 50px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.benefitItem {
  width: 320px;
  text-align: center;

  @media only screen and (max-width: 720px) {
    display: flex;
    gap: 30px;
    align-items: center;
  }
}

.benefitImageContainer {
  height: 50px;
  min-height: 50px;
  width: 50px;
  min-width: 50px;
  margin: auto;
}
