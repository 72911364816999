/*Search Page*/

.search-results {
  &__wrapper {
    border-bottom: 1px solid #eaeaea;
    margin: 20px 0px 10px;
  }

  .MuiSelect-select:focus {
    background: none;
  }

  &__number {
    top: 13px;
    color: rgb(36, 41, 47);
    right: 0px;
    margin-left: 7px;
    padding-top: 2px;
    background-color: rgba(175, 184, 194, 0.4);
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: 300;
    width: 25px;
    height: 25px;
    display: inline-block;
  }
  &__loading {
    color: hsla(213, 5%, 55%, 1);
    margin-left: 7px;
    font-size: 10px;
    font-weight: 300;
    display: inline-block;
  }
  &__filter-separator {
    margin: 2rem 0 0.5rem;
    padding: 0.5rem 0;
    border-top: 1px solid #e4e4e4;
    width: 100%;
  }

  .company-link svg {
    margin: auto;
    display: block;
    color: #242f51;
  }

  .company-link h3 {
    color: #242f51;
    font-weight: 500;
    @media (min-width: $md) {
      text-align: center;
    }
  }

  .company-description p {
    margin: 0;
  }
  .company-about {
    color: $turquoise;
    font-weight: 400;
    font-size: 0.875rem;
    text-align: center;
    margin: 0;

    @media (min-width: $md) {
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      text-align: left;
      margin: 10px 0 10px 0;
    }

    &--small {
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .company-description {
    margin: 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.claim-presence-box {
  margin: 2rem 0;
  padding: 60px 0;
  border-top: 1px solid #e4e4e4;
}
.shallow-hero {
  background-image: url(../images/mountains-bg.png);
  background-size: cover;
  background-position: center center;
  min-height: 250px;

  > div {
    max-width: $maxWidth;
    margin: 0px auto;
  }

  .search-heading {
    margin-bottom: 0px;
  }
}

.results--featured-companies {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 20px;
  margin-bottom: 40px;

  h3 {
    color: #eb5b44;
    font-weight: 300;
  }

  .MuiPaper-root {
    border: 1px solid #ffcdc5;
  }
}

.filter-selection {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e4e4e4;

  svg {
    color: $orange;
  }
}

.filter-results {
  color: #242f51;
  font-weight: 400;
}

.filter-title {
  font-size: 13px;
  font-weight: 500;
}

.search-results-list {
  margin: 0;
  padding: 30px 0;

  &__search-again {
    padding: 120px 0;
  }
  &__no-results {
    padding: 120px 0;
    margin-bottom: 40px;
  }
}

/* TODO: move these to MUI styles */

#menu- .MuiCheckbox-colorSecondary.Mui-checked {
  color: $orange;
}

.search-results .MuiOutlinedInput-root.Mui-disabled {
  border-radius: 30px;
  border: 1px solid lightgrey;
}

.search-results .MuiOutlinedInput-root,
.filter-selection .MuiChip-root {
  border-radius: 30px;
  border: 1px solid $orange;
  font-size: 0.875rem;
}

#menu- .MuiPaper-root {
  border: 1px solid $orange;
}

.search-results .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.search-results .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 0px;
}
.search-results .MuiPaper-root {
  background-color: #f5f7fa;
}
.MuiCircularProgress-root {
  display: block;
  margin: 75px auto;
}
.MuiCircularProgress-colorPrimary {
  color: $turquoise !important;
}
/* Hide dropdown arrow when no options in selector */
.MuiSelect-iconOutlined.Mui-disabled {
  color: transparent !important;
}
/* Remove background from MUI checkboxes when selected */
.MuiListItem-button.Mui-selected {
  background-color: inherit !important;
}
